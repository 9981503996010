import React from "react";
import Image from "next/image";
import { testimonialsReviews } from "DataMapper/Refi/LandingPageConstants";

function Testimonials() {
  return (
    <section className="py-[40px] px-[16px] md:py-[60px] bg-[#D9D8EA]">
      <h2 className="text-[24px] md:text-[36px] text-[#1B1D6A] md:text-center font-bold leading-[28px] md:leading-[46px]">
        Trusted by 1,238+ students &amp; working professionals
      </h2>
      <div className="block md:grid grid-cols-2 gap-3 mt-[18px] md:mt-[64px] md:px-16">
        {testimonialsReviews.map((item, index) => (
          <div
            className="p-[16px] md:p-[24px] mb-[20px] md:mb-0 bg-[#FFFFFF] rounded"
            key={`testimonial-${index}`}
          >
            <div className="text-[#1B1D6A] leading-[21px] md:leading-[30px] text-[14px] md:text-[20px]">
              {item.review}{" "}
            </div>
            <div className="flex justify-between mt-3">
              <div className="text-[#1B1D6A] font-bold text-[15px] flex items-center">
                {item.name}&nbsp;{" "}
                {item.socialProof.map((element: any) => (
                  <Image
                    src={element.image}
                    alt={element.alt}
                    width={24}
                    height={24}
                    className="cursor-pointer mr-2"
                    key={`social-proof-${index}`}
                    onClick={() => window.open(element.link, "_blank")}
                  />
                ))}
              </div>
              <div className="flex items-center">
                <span className="text-[#1B1D6A] text-[16px] mt-1 ml-2">
                  {item.rating}
                </span>
                <Image
                  src={item.ratingImage}
                  alt="rating image"
                  width={20}
                  height={20}
                />
              </div>
            </div>
            <div className="text-[#6964FF] text-[12px]">{item.collegeName}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Testimonials;
